import { createMuiTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f15928',
    },
  },
  overrides: {
    MuiAppBar: {},
  },
})

export const headerStyles = makeStyles(theme => ({
  logo: {
    fontFamily: 'Lobster',
    fontSize: 38,
    flexGrow: 1,

  },
}))

export default theme
